import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import locales from '../assets/json/locales.json'
export default new Vuex.Store({
  state: {
    language:sessionStorage.getItem("language")||'',
    languageCode: [
      "zh-Hans", "zh-Hant", "en", "fr", "de", "it", "ja", "ko", "pt", "ru", "es", "ar", "hr", "cs", "da", "nl", "fi", "el", "he", "hi", "hu", "id", "ms", "nb", "pl", "ro", "sk", "sv", "th", "tr", "uk", "vi"
    ],
    page_data:locales.en
  },
  getters: {
  },
  mutations: {
    setLanguage(state, data) {
      if (state.languageCode.indexOf(data) == -1) {
        state.language = 'en'
      } else {
        state.language = data
      }
      sessionStorage.setItem("language", state.language);
      state.page_data=locales[state.language]
    }
  },
  actions: {
  },
  modules: {
  }
})
