<template>
  <div class="home">
    <div class="header">
      <div>
        <img style="height:32px" src="../assets/logo.png" alt="">
        <div class="right">
          <span style="font-size: 14px;">{{ page_data.home }}</span>
          <el-button @click="toIpaddress" style="margin-left:16px;" type="text">{{ page_data.ipaddress }}</el-button>
          <!-- <a href="/ips.html">IP地址</a> -->
        </div>
      </div>
    </div>
    <div class="first">
      <h1>{{ page_data.searchTitle }}</h1>
      <div style="position: relative;">
        <div class="storeCountry">
          <img :src="require(`../assets/flag/${countryCode}.png`)" style="width:20px;margin-right: 6px;" alt="">
          <span style="max-width:100px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">
            {{ languageName }}
          </span>
          <i class="el-icon-arrow-down" style="font-weight:bold;margin-left:10px"></i>
        </div>
        <el-select @visible-change="changeActive" :style="active ? 'opacity: 1;' : 'opacity: 0;'" class="select"
          size="small" @change="changeLanguage" v-model="language" filterable :placeholder="page_data.select">
          <el-option v-for="item in languageList" :key="item.code" :label="item.localName" :value="item.code"
            style="display:flex;align-items:center">
            <img style="width:24px;margin-right: 8px;" :src="require(`../assets/flag/${item.countryCode}.png`)" alt="">
            <span style=" max-width: 120px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{
              item.localName }}</span>
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="nav">
      <a href="">{{ page_data.home }}</a>
      <span class="split">/</span>
      <span>{{ page_data.searchTitle }}</span>
    </div>
    <div class="ipSearch">
      <div style="display: flex;margin-bottom:40px">
        <el-input v-model="ipInput" size="small" :placeholder="page_data.placeholder"></el-input>
        <el-button size="small" @click="search" icon="el-icon-search"></el-button>
      </div>
      <div class="ip">{{ ip }}</div>
      <div class="flag">
        <img :src="require(`../assets/flag/${countryIsoCode}.png`)" alt="">
      </div>
    </div>
    <div class="info">
      <div class="title">{{ page_data.ipInfo }}</div>
      <div class="list">
        <div class="list-item" v-for="(item, index) of ipInfos" :key="index">
          <div>{{ page_data[item.key] || '-' }}</div>
          <div :style="{ color: `${item.color ? item.color : '#000'}` }">{{
            item.key == 'isInEuropeanUnion' ? (ipInfo[item.key] ? 'YES' : 'NO') : ipInfo[item.key] ? ipInfo[item.key] +
              (item.unit ?
                item.unit : '') : '-' }}</div>
        </div>
      </div>
    </div>
    <div class="mapArea">
      <div id="map" style="width:100%;height:320px;"></div>
    </div>
    <!-- <div class="info last">
      <div class="last-info">
        <div class="title" style="margin-bottom: 20px;">
          浏览器信息
        </div>
        <div class="list-item" style="width: 100%;" v-for="(item, index) of webInfos" :key="index">
          <div>{{ item.title }}</div>
          <div :style="{ color: `${item.color ? item.color : '#000'}` }">{{ item.value }}</div>
        </div>
      </div>
      <div class="last-info">
        <div class="title" style="margin-bottom: 20px;">国家信息</div>
        <div class="list-item" style="width: 100%;" v-for="(item, index) of countryInfos" :key="index">
          <div>{{ item.title }}</div>
          <div :style="{ color: `${item.color ? item.color : '#000'}` }">{{ ipInfo[item.key] || '-' }}</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// 引入 js-md5 库
const md5 = require('js-md5');
// 引入 sha1 库
const sha1 = require('sha1');
// @ is an alias to /src
import languageList from "../assets/json/languageList.json"
import { Loader } from "@googlemaps/js-api-loader";
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      map: null,
      markers: [
        {
          position: {
            lat: 37.7749,
            lng: -122.4194,
          },
          content: "<h3>San Francisco</h3>",
          title: ""
        }
      ],
      ipInfo: {},
      ipInfos: [
        {
          title: "IP地址：",
          key: "ip",
          value: "47.57.5.16",
          color: "#006dff"
        },
        {
          title: "邮政编码：",
          key: "postalCode",
          value: "-"
        },
        {
          title: "ISP：",
          key: "isp",
          value: "AliCloud",
          color: "#006dff"
        },
        {
          title: "城市ID：",
          key: "cityGeoNameId",
          value: "02522"
        },
        {
          title: "连接速度：",
          key: "speed",
          value: "-"
        },
        {
          title: "人均收入",
          key: "averageIncome：",
          value: "852"
        },
        {
          title: "市：",
          key: "cityName",
          value: "Hong Kong"
        },
        {
          title: "人口密度：",
          key: "populationDensity",
          value: "-"
        },
        {
          title: "国家：",
          key: "country",
          value: "Hong Kong"
        },
        {
          title: "精度",
          key: "accuracyRadius",
          value: "-",
          unit: "km"
        },
        {
          title: "洲：",
          key: "continentName",
          value: "-"
        },
        {
          title: "域名：",
          key: "domain",
          value: "alicode.com",
          color: "#006dff"
        },
        {
          title: "纬度：",
          key: "latitude",
          value: "22.28552"
        },
        {
          title: "移动设备网络代码（MNC）：",
          key: "mobileNetworkCode",
          value: "-"
        },
        {
          title: "经度：",
          key: "longitude",
          value: "114.15769"
        },
        {
          title: "移动设备国家代码（MCC）：",
          key: "mobileCountryCode",
          value: "-"
        },
        {
          title: "时区：",
          key: "timeZone",
          value: "UTC +8:00"
        },
        {
          title: "城市代码：",
          key: "metroCode",
          value: "-"
        },
        {
          title: "当地时间：",
          key: "localTime",
          value: "20 Jun,2023 01:33 PM"
        },
        {
          title: "海拔：",
          key: "altitude",
          value: "3 meters"
        },
        {
          title: "运营商：",
          key: "organization",
          value: "No"
        },
        {
          title: "ASN号码：",
          key: "asn",
          value: "455102"
        },
        {
          title: "代理服务器：",
          key: "server",
          value: "-"
        },
        {
          title: "ASN名称：",
          key: "asnName",
          value: "-"
        },
        {
          title: "地址类型：",
          key: "address",
          value: "(U) Unicast"
        },
        {
          title: "欧盟国家：",
          key: "isInEuropeanUnion",
          value: "-"
        },
      ],
      languageList: languageList,
      language: 'zh-Hans',
      active: false,
      webInfos: [
        {
          title: "用户代理：",
          key: "user",
          value: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10 15 7)AppleWebKit/537.36 (KHTML, like Gecko)Chrome/114.0.0.0 Safari/537.36",
        },
        {
          title: "介绍人：",
          key: "introducer",
          value: "https://www.ipaddress.my/"
        },
        {
          title: "设备：",
          key: "device",
          value: "Desktop",
        },
        {
          title: "操作系统：",
          key: "os",
          value: "MacOSX"
        },
        {
          title: "结构：",
          key: "structure",
          value: "32 bits"
        },
        {
          title: "浏览器：",
          key: "browser",
          value: "Chrome Generic"
        }
      ],
      countryInfos: [
        {
          title: "大陆：",
          key: "continentName",
          value: "Asia"
        },
        {
          title: "国家：",
          key: "country",
          value: "Hong Kong"
        },
        {
          title: "城市：",
          key: "cityName",
          value: "Hong Kong"
        },

        {
          title: "人口：",
          key: "dd",
          value: "Hong Kong"
        },
        {
          title: "面积：",
          key: "cc",
          value: "1092km²"
        },
        {
          title: "货币：",
          key: "ff",
          value: "(HKD) Dollar"
        },
        {
          title: "顶级域名：",
          key: "bb",
          value: ".hk"
        }
      ],
      ip: "",
      ipInput: "",
    }
  },
  mounted() {
    if (this.$route.query.ip) {
      this.ip = this.$route.query.ip
      this.getIpInfo(this.ip)
    } else {
      this.getIpInfo()
    }
    this.getLanguage()
  },
  computed: {
    page_data() {
      return this.$store.state.page_data
    },
    storeLanguage() {
      return this.$store.state.language
    },
    countryIsoCode() {
      return this.ipInfo && this.ipInfo.countryIsoCode ? this.ipInfo.countryIsoCode : 'US'
    },
    countryCode() {
      let countryCode = this.languageList.find(it => {
        return it.code == this.language
      }).countryCode
      return countryCode
    },
    languageName() {
      let localName = this.languageList.find(it => {
        return it.code == this.language
      }).localName
      return localName
    }

  },
  methods: {
    changeLanguage(e) {
      this.$store.commit("setLanguage", e);
    },
    getLanguage() {
      if (!this.storeLanguage) {
        let language = this.$route.query.language || 'en';
        this.$store.commit("setLanguage", language);
        this.language = this.storeLanguage;
      } else {
        this.language = this.storeLanguage;
        this.$store.commit("setLanguage", this.language);
      }
    },
    convertTimestampToTimezone(timestamp, targetTimeZone) {
      const date = new Date(timestamp);
      const options = {
        timeZone: targetTimeZone,
        timeZoneName: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      };
      const formatter = new Intl.DateTimeFormat('default', options);
      return formatter.format(date);
    },
    // 获取随机位数字符串（a：字符数）
    getstr(a) {
      var d,
        e,
        b = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        c = "";
      for (d = 0; a > d; d += 1)
        (e = Math.random() * b.length), (e = Math.floor(e)), (c += b.charAt(e));
      return c;
    },
    getIpInfo(ip) {
      let url = 'https://api.ipsearch.link/ip_info'
      if (ip) {
        url = 'https://api.ipsearch.link/ip_info?ip=' + ip
      }
      let nonce = this.getstr(14)
      let ts = Date.now();
      let lastDigit = parseInt(ts.toString().slice(-1));
      let newNonce = nonce.slice(lastDigit)
      let str = sha1(md5(newNonce) + ts)
      fetch(url, {
        headers: {
          "X-Nonce": nonce,
          "X-TimeStamp": ts,
          "X-Signature": str
        }
      }).then(response => response.json())
        .then(data => {
          this.ipInfo = data;
          this.ip = data.ip;
          this.ipInfo.localTime = this.convertTimestampToTimezone(data.timestamp, data.timeZone)
          this.markers = [
            {
              "position": {
                lat: data.latitude,
                lng: data.longitude,
              },
              title: data.cityName,
              content: `<h3>${data.cityName}</h3>`,
            }
          ]
          this.loadMap()
        })
        .catch(error => console.error(error))
    },
    loadMap() {
      const loader = new Loader({
        apiKey: "AIzaSyBsYxSJggE9r_UzS2Y2n5wcDXUgmZSTllY",
        version: "weekly",
      });
      loader.load().then(() => {
        // eslint-disable-next-line
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: this.markers[0].position,
          zoom: 10,
        });
        this.markers.forEach((marker) => {
          // eslint-disable-next-line
          new google.maps.Marker({
            position: marker.position,
            map: this.map,
            title: marker.title,
            content: marker.content
          });
        });
      })
    },
    search() {
      if (this.isValidIP(this.ipInput)) {
        this.getIpInfo(this.ipInput)
      } else {
        this.$message.error("ip格式错误！")
      }
    },
    isValidIP(ip) {
      // 判断长度，合法的IP地址应该是4段数字组成，每段数字之间用 "." 连接
      if (ip.length < 7 || ip.length > 15) {
        return false;
      }

      // 判断格式，每段数字的范围在0~255之间，且不允许前导0
      const parts = ip.split(".");
      if (parts.length !== 4) {
        return false;
      }
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        if (part.length === 0 || part.length > 3 || (part[0] === '0' && part.length > 1) || isNaN(parseInt(part)) || parseInt(part) < 0 || parseInt(part) > 255) {
          return false;
        }
      }
      // 判断大小，IP地址应该在以下范围内：A类地址：1.0.0.0~126.255.255.255，B类地址：128.0.0.0~191.255.255.255，C类地址：192.0.0.0~223.255.255.255
      const ipNum = parts.map(part => parseInt(part));
      if (ipNum[0] === 0 || ipNum[0] > 223) {
        return false;
      } else if (ipNum[0] === 127) {
        return false; // 回环地址
      } else if (ipNum[0] <= 126 && ipNum[1] === 0 && ipNum[2] === 0) {
        return false; // A类地址前三段不能全为0
      } else if (ipNum[0] === 169 && ipNum[1] === 254) {
        return false; // 保留地址
      } else if (ipNum[0] <= 191 && ipNum[1] === 168) {
        return false; // B类私有地址
      } else if (ipNum[0] === 192 && ipNum[1] === 0 && ipNum[2] === 2) {
        return false; // 保留地址
      } else if (ipNum[0] === 192 && ipNum[1] === 88 && ipNum[2] === 99) {
        return false; // 保留地址
      } else if (ipNum[0] === 192 && ipNum[1] === 168 && (ipNum[2] < 0 || ipNum[2] > 255)) {
        return false; // C类私有地址
      }

      // 如果所有判断都通过，则该IP地址合法
      return true;
    },
    getIp() {
      return '127.0.0.1'
    },
    changeActive(e) {
      if (e) {
        this.active = true
      } else {
        this.active = false

      }
    },
    toIpaddress() {
      this.$router.push('/ipaddress')
    }
  }

}
</script>
<style scoped>
.header {
  /* background-color: #222; */
  /* border-color: #080808; */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 100;
  padding: 0 80px;
  box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.header>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  max-width: 1140px;
  margin: 0 auto;
}


.header .left {
  font-size: 24px;
  font-weight: 400;
  color: #a1c2f1;
  line-height: 32px;
}

.header .right {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 22px;
}

.header .right>a {
  text-decoration: none;
  margin-left: 16px;
  color: #999;
}

.header .right>a:hover {
  color: #000;
}

.home {
  padding: 0 80px;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

.first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 90px auto 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.first>h1 {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 33px;
}

.storeCountry {
  padding: 6px 11px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  cursor: pointer;
}

.select {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.nav {
  max-width: 1140px;
  margin: 0 auto 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 8px 15px;
  box-sizing: border-box;
}

.nav>.split {
  color: #ccc;
  margin: 0 5px;
}

.nav>a {
  text-decoration: none;
  color: #006dff;
}

.ipSearch {
  max-width: 720px;
  margin: 0 auto 48px;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.ipSearch .el-input__inner {
  border-radius: 4px 0 0 4px;
}

.ipSearch .el-button {
  border-radius: 0 4px 4px 0;
  height: 32px;
  border-left: 0;
}

.ipSearch .robot {
  width: 300px;
}

.ipSearch .ip {
  text-align: center;
  font-size: 35px;
  color: #777;
  margin-bottom: 16px;
}

.ipSearch .flag {
  text-align: center;
  padding-bottom: 20px;
}

.ipSearch .flag>img {
  width: 56px;
}

.mapArea {
  max-width: 1140px;
  margin: 0px auto 40px;
}

.info {
  max-width: 1140px;
  margin: 0px auto 40px;
}

.info .title {
  width: 100%;
  padding-bottom: 20px;
  margin: 0;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 33px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.info>.list {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.info .list-item {
  width: calc((100% - 48px)/2);
  padding: 8px 4px;
  border-top: 1px solid rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;
}

.info .list-item>div:first-child {
  width: 45%;
  margin-right: 10%;
}

.info .list-item>div:last-child {
  width: 45%;
}

.last {
  display: flex;
  justify-content: space-between;
}

.last-info {
  width: calc((100% - 48px)/2);
}

@media (max-width: 768px) {
  .home {
    padding: 0 8px;
  }

  .header {
    padding: 0 8px;
  }

  /* 小于768像素的屏幕样式 */
  .info .list-item {
    width: 100%;
  }

  .last {
    display: block;
  }

  .last-info {
    width: 100%;
    margin-bottom: 48px;
  }



}

@media (min-width: 768px) and (max-width: 1024px) {

  /* 768像素到1024像素屏幕的样式 */
  .home {
    padding: 0 40px;
  }

  .header {
    padding: 0 40px;
  }
}

@media (min-width: 1024px) {
  /* 大于1024像素的屏幕样式 */
}
</style>
